
import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby'

import {Layout} from '../components/index';
import SubscribeBlock from '../components/SubscribeBlock'
import {htmlToReact, Link} from '../utils';
import Img from 'gatsby-image';
import styled from'styled-components';

const SplitContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  background-color: ${props => props.bg || 'white'};

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const BioLink = styled(Link)`
  align-self: center;
  justify-self: center;
  width: 100%;
  position: relative;
`;

const BioText = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;

  h2, p {
    margin-bottom: 0;
    color: white;
  }
`;

export default class About extends React.Component {
  render() {
    const data = this.props.data;
    return (
      <Layout {...this.props}>
        <SplitContainer className="outer">
          <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
          <div className="post-content">
            {htmlToReact(_.get(this.props, 'pageContext.html'))}
          </div>
        </SplitContainer>
        <SplitContainer className="outer" bg="#FF6367">
          <BioLink to="/about/jeff" bgimg="https://via.placeholder.com/300.png">
            <Img fixed={data.jeff.childImageSharp.fixed} style={{height: "60vh", width: "100%"}}/>
            <BioText>
              <h2>JEFF HENDERSON</h2>
              <p>CEO, The FOR Company</p>
            </BioText>
          </BioLink>
          <BioLink to="/about/kevin" bgimg="https://via.placeholder.com/300.png">
            <Img fixed={data.kevin.childImageSharp.fixed} style={{height: "60vh", width: "100%"}}/>
            <BioText>
              <h2>KEVIN JENNINGS</h2>
              <p>Personal Brand Strategist &amp;<br/>CEO, Junction 32</p>
            </BioText>
          </BioLink>
          <BioLink to="/about/shane" bgimg="https://via.placeholder.com/300.png">
            <Img fixed={data.shane.childImageSharp.fixed} style={{height: "60vh", width: "100%"}}/>
            <BioText>
              <h2>SHANE BENSON</h2>
              <p>SVP of Operations,<br/>Chick-fil-A</p>
            </BioText>
          </BioLink>
          <BioLink to="/about/david" bgimg="https://via.placeholder.com/300.png">
            <Img fixed={data.david.childImageSharp.fixed} style={{height: "60vh", width: "100%"}}/>
            <BioText>
              <h2>DAVID FARMER</h2>
              <p> SVP of Restaurant Experience,<br/>Chick-fil-A</p>
            </BioText>
          </BioLink>
        </SplitContainer>
        <SubscribeBlock pageContext={{ frontmatter: { subscribe_title: "Stay in the loop" } }} {...this.props} />

      </Layout>
    );
  }
}

export const query = graphql`
  query {
    jeff: file(absolutePath: {regex: "/jeff.jpg/"}) {
        childImageSharp {
          fixed(width: 2000) {
            src
          }
        }
      }
    kevin: file(absolutePath: {regex: "/kevin.jpg/"}) {
        childImageSharp {
          fixed(width: 2000) {
            src
          }
        }
      }
    shane: file(absolutePath: {regex: "/shane.jpg/"}) {
        childImageSharp {
          fixed(width: 2000) {
            src
          }
        }
      }
    david: file(absolutePath: {regex: "/david.jpg/"}) {
        childImageSharp {
          fixed(width: 2000) {
            src
          }
        }
      }
  }
  
  
`
